import { ErrorHandler } from '@angular/core';

/**
 * This interface is needed because the application which uses the alfred client component
 * doesn't need to have a SentryErrorHandler
 */
interface SentryErrorHandlerTypeDef {
  handleError(err: unknown): void;
  captureError(err: unknown): Promise<string>;
}

/**
 * This function check if the given ErrorHandler is a SentryErrorHandler without
 * the need to include the SentryErrorHandler definition.
 * @param errorHandler
 */
export function isSentryErrorHandler(
  errorHandler: ErrorHandler | SentryErrorHandlerTypeDef,
): errorHandler is SentryErrorHandlerTypeDef {
  const thisHandler: SentryErrorHandlerTypeDef = errorHandler as SentryErrorHandlerTypeDef;
  return typeof thisHandler.captureError === 'function';
}

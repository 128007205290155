/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EnvironmentProviders,
  ErrorHandler,
  inject,
  provideAppInitializer,
  Provider,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  browserTracingIntegration,
  feedbackIntegration,
  feedbackSyncIntegration,
  init,
  replayIntegration,
  TraceService,
} from '@sentry/angular';
import {
  FeedbackGeneralConfiguration,
  FeedbackThemeConfiguration,
} from '@sentry/core/build/types/types-hoist/feedback/config';
import { Object } from 'ts-toolbelt';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { SentryWebConfig } from './sentry-web.config';

export abstract class UserFeedbackIntegration {
  public abstract attachTo(
    el: string | Element,
    optionOverrides?: Parameters<ReturnType<typeof feedbackSyncIntegration>['attachTo']>[1],
  ): () => void;
  public abstract createForm(
    optionOverrides?: Parameters<ReturnType<typeof feedbackSyncIntegration>['createForm']>[0],
  ): Promise<{
    el: unknown;
    appendToDom: () => void;
    removeFromDom: () => void;
    open: () => void;
    close: () => void;
  }>;
  public abstract createWidget(
    optionOverrides?: Parameters<ReturnType<typeof feedbackSyncIntegration>['createWidget']>[0],
  ): ReturnType<ReturnType<typeof feedbackSyncIntegration>['createWidget']>;
  public abstract remove(): void;
}

interface Feature {
  type: 'withTracing' | 'withUserFeedback' | 'withSentry';
  integrations: Parameters<typeof init>[0]['integrations'];

  providers: (Provider | EnvironmentProviders)[];
}

export function withTracing(): Feature {
  return {
    type: 'withTracing',
    providers: [
      { provide: TraceService, deps: [Router] },
      provideAppInitializer(() => {
        inject(TraceService);
      }),
    ],
    integrations: [replayIntegration(), browserTracingIntegration()],
  };
}

export function withUserFeedback(
  options?: Partial<Object.Merge<FeedbackThemeConfiguration, FeedbackGeneralConfiguration>>,
): Feature {
  const feedbackIntegrationInstance = feedbackSyncIntegration(options);
  return {
    type: 'withUserFeedback',
    integrations: [feedbackIntegrationInstance],
    providers: [{ provide: UserFeedbackIntegration, useValue: feedbackIntegrationInstance }],
  };
}

//eslint-disable-next-line max-lines-per-function
export function provideSentry(
  options: SentryWebConfig,
  ...features: (
    | (Provider | EnvironmentProviders)[]
    | {
        type: string;
        integrations: Parameters<typeof init>[0]['integrations'];
        providers: (Provider | EnvironmentProviders)[];
        init?: (passedOptions: any) => void;
        options?: any;
      }
  )[]
) {
  //eslint-disable-next-line @typescript-eslint/no-misused-spread
  options = { ...new SentryWebConfig(), ...options };

  let initFn: ((initOptions: any, originalInit: (origOptions: any) => void) => void) | undefined;
  const featureProviders: (Provider | EnvironmentProviders)[] = [];
  const integrations: Parameters<typeof init>[0]['integrations'] = [];
  for (const feature of features) {
    if (Array.isArray(feature)) {
      featureProviders.push(...feature);
      continue;
    }
    switch (feature.type) {
      case 'withSentry':
        initFn = feature.init;
        //eslint-disable-next-line @typescript-eslint/no-misused-spread
        options = { ...options, ...feature.options };
        break;
      case 'withTracing':
        options.enableTracing = true;
        break;
    }
    featureProviders.push(...feature.providers);
    integrations.push(...(feature.integrations as any));
  }
  if (initFn) {
    initFn(
      {
        //eslint-disable-next-line @typescript-eslint/no-misused-spread
        ...options,
        enabled: true,
        defaultIntegrations: false,
        integrations: integrations,
      },
      init,
    );
  } else {
    init({
      //eslint-disable-next-line @typescript-eslint/no-misused-spread
      ...options,
      enabled: true,
      defaultIntegrations: false,
      integrations: integrations,
    });
  }

  return [
    { provide: SentryWebConfig, useValue: options },
    { provide: SentryErrorHandler },
    { provide: ErrorHandler, useExisting: SentryErrorHandler },
    ...featureProviders,
  ];
}

import { BrowserOptions } from '@sentry/angular';
import { SentryEnvironment } from './sentry-web.config';

export class SentryBrowserConfig implements BrowserOptions {
  /**
   * The DSN used to connect to Sentry and identify the project. If omitted, the
   * SDK will not send any data to Sentry.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#dsn
   */
  public dsn?: string;

  /**
   * The release identifier used when uploading respective source maps. Specify
   * this value to allow Sentry to resolve the correct source maps when
   * processing events.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#release
   */
  public release?: string;

  /**
   * Environment for which the application is compiled. This Property is used as:
   *   - Sentry environment.
   *   - To determine if the application is run in production environment
   *   - To determine if the application is run in local (development) environment
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#environment
   */
  public environment: SentryEnvironment = SentryEnvironment.LOCAL;

  /**
   * Enable debug functionality in the SDK itself
   */
  public debug?: boolean = false;

  /**
   * Specifies whether this SDK should send events to Sentry.
   * Defaults to true.
   */
  public enabled?: boolean = true;

  /**
   * A pattern for error URLs which should exclusively be sent to Sentry.
   * This is the opposite of {@link self.denyUrls}.
   * By default, all errors will be sent.
   *
   * https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#allow-urls
   */
  public allowUrls?: (string | RegExp)[];

  /**
   * A pattern for error URLs which should not be sent to Sentry.
   * To allow certain errors instead, use {@link self.allowUrls}.
   * By default, all errors will be sent.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#deny-urls
   */
  public denyUrls?: (string | RegExp)[];

  /**
   * A flag enabling Sessions Tracking feature.
   * By default Sessions Tracking is enable.
   * https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#auto-session-tracking
   */
  public autoSessionTracking?: boolean = true;

  /**
   * A pattern for error messages which should not be sent to Sentry.
   * By default, all errors will be sent.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/filtering/
   */
  public ignoreErrors?: (string | RegExp)[];

  /**
   * A URL to an envelope tunnel endpoint. An envelope tunnel is an HTTP endpoint
   * that accepts Sentry envelopes for forwarding. This can be used to force data
   * through a custom server independent of the type of data.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#tunnel
   */
  public tunnel?: string;

  /**
   * The maximum number of breadcrumbs sent with events. Defaults to 100.
   * Values over 100 will be ignored and 100 used instead.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#max-breadcrumbs
   */
  public maxBreadcrumbs?: number = 100;

  /**
   * Configures the sample rate for error events, in the range of 0.0 to 1.0.
   * The default is 1.0 which means that 100% of error events are sent.
   * If set to 0.1 only 10% of error events will be sent. Events are picked randomly.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#sample-rate
   */
  public sampleRate?: number = 1.0;

  /**
   * Maximum number of characters a single value can have before it
   * will be truncated (defaults to 250).
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#max-value-length
   */
  public maxValueLength?: number = 250;

  /**
   * Maximum number of levels that normalization algorithm will traverse in objects and arrays.
   * Used when normalizing an event before sending, on all of the listed attributes:
   * - `breadcrumbs.data`
   * - `user`
   * - `contexts`
   * - `extra`
   * Defaults to `3`. Set to `0` to disable.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#normalize-depth
   */
  public normalizeDepth?: number;

  /**
   * Controls how many milliseconds to wait before shutting down. The default is
   * SDK-specific but typically around 2 seconds. Setting this too low can cause
   * problems for sending events from command line applications. Setting it too
   * high can cause the application to block for users with network connectivity
   * problems.
   */
  public shutdownTimeout?: number;

  /**
   * Sample rate to determine trace sampling.
   *
   * 0.0 = 0% chance of a given trace being sent (send no traces) 1.0 = 100% chance of a given trace being sent (send
   * all traces)
   *
   * Tracing is enabled if either this or `tracesSampler` is defined. If both are defined, `tracesSampleRate` is
   * ignored.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#traces-sample-rate
   */
  public tracesSampleRate?: number;

  /**
   * Initial data to populate scope.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#initial-scope
   */
  public initialScope?: BrowserOptions['initialScope'];

  /**
   * A callback invoked when adding a breadcrumb, allowing to optionally modify
   * it before adding it to future events.
   *
   * Note that you must return a valid breadcrumb from this callback. If you do
   * not wish to modify the breadcrumb, simply return it at the end.
   * Returning null will cause the breadcrumb to be dropped.
   *
   * @param breadcrumb The breadcrumb as created by the SDK.
   * @param hint
   * @returns The breadcrumb that will be added | null.
   *
   * @see https://docs.sentry.io/platforms/javascript/guides/angular/configuration/options/#before-breadcrumb
   */
  public beforeBreadcrumb?: BrowserOptions['beforeBreadcrumb'];

  /**
   * Options for the default transport that the SDK uses.
   */
  public transportOptions?: BrowserOptions['transportOptions'];

  /**
   * Sets the distribution for all events
   */
  public dist?: string;

  /**
   * Attaches stacktraces to pure capture message / log integrations
   */
  public attachStacktrace?: boolean;

  public integrations?: BrowserOptions['integrations'];
}

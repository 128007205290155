/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentProviders, ErrorHandler, Provider } from '@angular/core';
import { init } from '@sentry/angular';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { SentryErrorHandlerMock } from './sentry-error-handler.service.mock';
import { SentryWebConfig } from './sentry-web.config';

export function provideSentryTesting(
  options: SentryWebConfig,
  ...features: (
    | (Provider | EnvironmentProviders)[]
    | {
        type: string;
        integrations: Parameters<typeof init>[0]['integrations'];
        providers: (Provider | EnvironmentProviders)[];
        init?: (passedOptions: any) => void;
        options?: any;
      }
  )[]
) {
  //eslint-disable-next-line @typescript-eslint/no-misused-spread
  options = { ...new SentryWebConfig(), ...options };

  const featureProviders: (Provider | EnvironmentProviders)[] = [];
  for (const feature of features) {
    if (Array.isArray(feature)) {
      featureProviders.push(...feature);
      continue;
    }
    featureProviders.push(...feature.providers);
  }

  return [
    ...featureProviders,
    { provide: SentryWebConfig, useValue: options },
    { provide: SentryErrorHandler, useClass: SentryErrorHandlerMock },
    { provide: ErrorHandler, useExisting: SentryErrorHandler },
  ];
}
